import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SkeletonedComponent = ({
  condition,
  content,
  children,
  height,
  width,
  classNameForSkeleton,
}) => {
  return condition ? (
    <>
      {content}
      {children}
    </>
  ) : (
    <Skeleton height={height} width={width} className={classNameForSkeleton} />
  );
};

SkeletonedComponent.propTypes = {
  condition: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  height: PropTypes.number,
  width: PropTypes.number,
  classNameForSkeleton: PropTypes.string,
};

export default SkeletonedComponent;
