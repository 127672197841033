import axios from 'axios';
import { API_DOMAIN } from '../globals';

const api = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
  },
});

let authToken = '';

export const setAuthToken = (token: string) => {
  authToken = token;
};

api.interceptors.request.use((config) => {
  if (authToken && config.headers) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return config;
});

export default api;
