import {
  columnMoveIcon,
  densityIcon,
  expandIcon,
  filterIcon,
  optionsIcon,
  searchIcon,
  sortIcon,
} from '../../../../assets/images/icons/table';

const TableIconsOverride: Record<string, () => JSX.Element> = {
  FilterListIcon: () => <img src={filterIcon} alt="filter" style={{ width: 12 }} />,
  SyncAltIcon: () => <img src={sortIcon} alt="sort" style={{ marginLeft: 9 }} />,
  DragHandleIcon: () => <img src={columnMoveIcon} alt="order" style={{ marginRight: 5 }} />,
  MoreVertIcon: () => <img src={optionsIcon} alt="option" />,
  FilterListOffIcon: () => <img src={filterIcon} alt="filter" style={{ width: 14 }} />,
  DensityLargeIcon: () => <img src={densityIcon} alt="density" />,
  DensitySmallIcon: () => <img src={densityIcon} alt="density" />,
  DensityMediumIcon: () => <img src={densityIcon} alt="density" />,
  FullscreenIcon: () => <img src={expandIcon} alt="fullscreen" />,
  FullscreenExitIcon: () => <img src={expandIcon} alt="fullscreen" />,
  SearchIcon: () => <img src={searchIcon} alt="search" />,
  SearchOffIcon: () => <img src={searchIcon} alt="search" />,
};

export default TableIconsOverride;
