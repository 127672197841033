import { lazy } from 'react';
import Loadable from '../layouts/loader/Loadable';

const HelloWorld = Loadable(lazy(() => import('../views/public/hello-world/HelloWorld')));

const PublicRouter = [
  {
    path: '/public',
    children: [{ path: '', element: <HelloWorld /> }],
  },
];

export default PublicRouter;
