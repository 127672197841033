import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import designStudioService from '../../../services/api/design-studio';
import { FlattenedAuditLogEntry, Project } from '../../../services/api/design-studio/types';
import { TableHeaderOverride, TableIconsOverride } from '../projects/components';

interface AuditChange {
  field: string;
  previousValue: unknown;
  newValue: unknown;
  timestamp: {
    _seconds: number;
    _nanoseconds: number;
  };
  localTimestamp: {
    _seconds: number;
    _nanoseconds: number;
  };
}

interface AuditLogEntry {
  id: string;
  entityId: string;
  entityType: string;
  entityName: string;
  action: 'CREATE' | 'UPDATE' | 'DELETE';
  changes: AuditChange[];
  userId: string;
  metadata: {
    ipAddress: string;
    endpoint: string;
  };
  timestamp: {
    _seconds: number;
    _nanoseconds: number;
  };
  localTimestamp: {
    _seconds: number;
    _nanoseconds: number;
  };
}

// interface AuditLogProps {
//   projectId: string;
// }

const getActionColor = (action: string) => {
  switch (action) {
    case 'CREATE':
      return 'bg-green-100 text-green-800';
    case 'UPDATE':
      return 'bg-blue-100 text-blue-800';
    case 'DELETE':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const formatValue = (value: unknown): string => {
  if (value === null || value === undefined) return '-';
  if (typeof value === 'number') return value.toLocaleString();
  if (value instanceof Date || (typeof value === 'string' && value.includes('T'))) {
    return new Date(value).toLocaleString();
  }
  return typeof value === 'object' ? JSON.stringify(value) : String(value);
};

const formatDateTime = (
  timestamp: { _seconds: number; _nanoseconds: number } | null | undefined,
): string => {
  if (!timestamp) return '-';
  try {
    const date = new Date(timestamp._seconds * 1000);
    return date.toLocaleString();
  } catch (e) {
    return '-';
  }
};

const flattenAuditLogEntries = (entries: AuditLogEntry[]): FlattenedAuditLogEntry[] => {
  return entries.flatMap((entry) =>
    entry.changes.map((change) => ({
      id: `${entry.id}-${change.field}`,
      entityId: entry.entityId,
      entityType: entry.entityType,
      entityName: entry.entityName,
      action: entry.action,
      userId: entry.userId,
      timestamp: entry.timestamp,
      field: change.field,
      previousValue: change.previousValue,
      newValue: change.newValue,
    })),
  );
};

const columns: MRT_ColumnDef<FlattenedAuditLogEntry>[] = [
  {
    accessorKey: 'timestamp',
    header: 'Date & Time',
    Cell: ({ row }) => formatDateTime(row.original.timestamp),
    size: 180,
    sortingFn: 'datetime',
  },
  {
    accessorKey: 'entityType',
    header: 'Domain',
    Cell: ({ row }) => (
      <span className="font-medium capitalize">{row.original.entityType || '-'}</span>
    ),
    size: 120,
  },
  {
    accessorKey: 'action',
    header: 'Action',
    Cell: ({ row }) => (
      <span
        className={`px-2 py-1 rounded-full text-xs font-medium ${getActionColor(
          row.original.action,
        )}`}
      >
        {row.original.action || '-'}
      </span>
    ),
    size: 100,
    filterFn: 'equals',
  },
  {
    accessorKey: 'field',
    header: 'Field',
    Cell: ({ row }) => (
      <span className="font-medium">
        {row.original.field?.replace(/([A-Z])/g, ' $1').toLowerCase() || '-'}
      </span>
    ),
    size: 150,
  },
  {
    accessorKey: 'previousValue',
    header: 'Previous Value',
    Cell: ({ row }) => (
      <div className="font-mono text-sm">{formatValue(row.original.previousValue)}</div>
    ),
    size: 200,
  },
  {
    accessorKey: 'newValue',
    header: 'New Value',
    Cell: ({ row }) => (
      <div className="font-mono text-sm">{formatValue(row.original.newValue)}</div>
    ),
    size: 200,
  },
  {
    accessorKey: 'userId',
    header: 'Modified By',
    Cell: ({ row }) => <span className="font-medium">{row.original.userId || '-'}</span>,
    size: 150,
  },
];

const AuditLog: FC = () => {
  const { project } = useOutletContext<{ project: Project }>();
  const [auditLogs, setAuditLogs] = useState<FlattenedAuditLogEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const fetchAuditLogs = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getAuditLogsEntries(
        project.id,
        pagination.pageIndex + 1,
        pagination.pageSize,
      );
      const flattenedLogs = flattenAuditLogEntries(response.data.data);
      setAuditLogs(flattenedLogs);
      setPagination((prev) => ({
        ...prev,
        pageCount: response.data.metadata.totalPages,
        totalElements: response.data.metadata.totalElements,
      }));
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [project.id, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    fetchAuditLogs();
  }, [fetchAuditLogs]);

  const tableData = useMemo(() => auditLogs, [auditLogs]);

  const tableState = useMemo(
    () => ({
      isLoading,
      pagination,
      showSkeletons: isLoading,
      showProgressBars: isLoading,
    }),
    [isLoading, pagination],
  );

  const tableProps = useMemo(
    () => ({
      sx: {
        '&::-webkit-scrollbar': {
          width: '10px',
          height: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    }),
    [],
  );

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading audit logs</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchAuditLogs}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="capexTable">
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        manualPagination
        onPaginationChange={setPagination}
        initialState={{
          density: 'compact',
          showColumnFilters: true,
        }}
        muiTableHeadCellProps={{
          sx: {
            borderBottom: '1px solid #DFD6C4',
          },
        }}
        muiTableBodyCellProps={() => ({
          sx: {
            cursor: 'pointer',
            borderBottom: 'none',
          },
        })}
        state={tableState}
        muiTableContainerProps={tableProps}
        renderTopToolbar={({ table }) => (
          <TableHeaderOverride<FlattenedAuditLogEntry> table={table} tableName="Audit Logs" />
        )}
        icons={TableIconsOverride}
      />
    </div>
  );
};

export default React.memo(AuditLog);
