import api from '../api';
import type { Address, Project } from './design-studio/types';

const designStudioService = {
  getProjects: () => api.get('/design-studio/projects?size=100'),
  createProject: (data: Partial<Project>) => api.post('/design-studio/projects', data),
  updateProject: (id: string, data: Partial<Project>) =>
    api.patch(`/design-studio/projects/${id}`, data),
  getProjectAddresses: (id: string) => api.get(`/design-studio/projects/${id}/addresses`),
  getDesignStatuses: () => api.get('/design-studio/ref/design-status'),
  getProperties: () => api.get('/design-studio/ref/property'),
  getProject: (id: string) => api.get(`/design-studio/projects/${id}`),
  getProjectContacts: (id: string) => api.get(`/design-studio/projects/${id}/contacts`),
  getFinancialStatuses: () => api.get('/design-studio/ref/financial-status'),
  getLeads() {
    return api.get('/design-studio/ref/leads');
  },
  getAuditLogsEntries: (id: string, page: number, size: number) =>
    api.get(`/design-studio/audit-logs/entities/${id}`, { params: { page, size } }),
  getProjectAddress: (projectId: string, addressId: string) =>
    api.get(`/design-studio/projects/${projectId}/addresses/${addressId}`),
  deleteProjectAddress: (projectId: string, addressId: string) =>
    api.delete(`/design-studio/projects/${projectId}/addresses/${addressId}`),
  createProjectAddress: (projectId: string, data: Partial<Address>) =>
    api.post(`/design-studio/projects/${projectId}/addresses`, data),
  updateProjectAddress: (projectId: string, addressId: string, data: Partial<Address>) =>
    api.put(`/design-studio/projects/${projectId}/addresses/${addressId}`, data),
};

export default designStudioService;
