import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import axios from 'axios';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import AuthRequiredModal from './components/authGuard/AuthRequiredModal';
import CorsErrorModal from './components/authGuard/CorsErrorModal';
import { AuthContext } from './contexts/auth';
import { API_DOMAIN, authConfig } from './globals';
import Loader from './layouts/loader/Loader';
import { setMetadata } from './store/metadata/MetadataSlice';

import PublicRouter from './routes/PublicRouter';
import OktaRouter from './routes/Router';

const METADATA = `${API_DOMAIN}/vision-api/metadata`;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const oktaAuth = useContext(AuthContext);
  const dispatch = useDispatch();

  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const isOktaPath = !location.pathname.startsWith('/public');
  const routing = useRoutes(isOktaPath ? OktaRouter : PublicRouter);

  const triggerLogin = async () => {
    return oktaAuth?.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth?.authStateManager.getPreviousAuthState();
    if (previousAuthState?.isAuthenticated) {
      setAuthRequiredModalOpen(true);
    } else {
      await triggerLogin();
    }
  };

  const fetchMetadata = async () => {
    const idToken = await oktaAuth?.tokenManager.get('idToken');
    if (!idToken) return;

    const { data } = await axios.get(METADATA, {
      headers: { Authorization: `Bearer ${idToken.idToken}` },
    });

    dispatch(setMetadata(data));
  };

  useEffect(() => {
    if (!oktaAuth) return undefined;

    const handleAuthStateChange = (authState) => {
      setIsAuthenticated(authState?.isAuthenticated ?? false);
    };

    oktaAuth.authStateManager.subscribe(handleAuthStateChange);

    const checkAuthState = async () => {
      const authState = await oktaAuth.authStateManager.getAuthState();
      setIsAuthenticated(authState?.isAuthenticated ?? false);
    };

    checkAuthState();

    return () => {
      oktaAuth.authStateManager.unsubscribe(handleAuthStateChange);
    };
  }, [oktaAuth]);

  useEffect(() => {
    if (isAuthenticated && isOktaPath) {
      fetchMetadata();
    }
  }, [isAuthenticated, isOktaPath]);

  return (
    <Suspense fallback={<Loader />}>
      <div
        data-testid="app-container"
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        {isOktaPath ? (
          <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
          >
            <CorsErrorModal
              issuer={authConfig.issuer}
              {...{ corsErrorModalOpen, setCorsErrorModalOpen }}
            />
            <AuthRequiredModal
              {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }}
            />
            {routing}
          </Security>
        ) : (
          routing
        )}
      </div>
    </Suspense>
  );
};

export default App;
