import axios from 'axios';
import { store } from './store/Store';

// Add request interceptor to the default axios instance
// this will attach impersonation headers to every request if impersonationing users
axios.interceptors.request.use((config) => {
  const state = store.getState();
  const { impersonation = {} } = state;

  if (impersonation.isImpersonating && impersonation.impersonatedUser) {
    config.headers['X-Impersonate-Email'] = impersonation.impersonatedUser.email;
    config.headers['X-Impersonate-Name'] = impersonation.impersonatedUser.name;
  }

  return config;
});
