import { components } from 'react-select';
import { ReactComponent as SelectArrowDownIcon } from '../assets/images/icons/SelectArrowDown.svg';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectArrowDownIcon />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
