import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { getPersistConfig } from 'redux-deep-persist';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ApiReducer from './api/ApiSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import GuestLookupReducer from './guest-lookup/GuestLookupSlice';
import GuestReducer from './guest/GuestSlice';
import menuReducer from './menu/menuSlice';
import ColumnsVisibilityReducer from './columnsVisibility/columnsVisibilitySlice';
import PropertiesReducer from './properties/PropertiesSlice';
import RequestsLogger from './requests-logger/RequestsLoggerSlice';
import TrainsReducer from './trains/TrainsSlice';
import UserPermissionsReducer from './user-permissions/UserPermissionsSlice';
import MetadataReducer from './metadata/MetadataSlice';
import ImpersonationReducer from './impersonation/ImpersonationSlice';

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  guest: GuestReducer,
  guestLookup: GuestLookupReducer,
  api: ApiReducer,
  userPermissions: UserPermissionsReducer,
  properties: PropertiesReducer,
  trains: TrainsReducer,
  requestsLogger: RequestsLogger,
  columnsVisibility: ColumnsVisibilityReducer,
  menu: menuReducer,
  metadata: MetadataReducer,
  impersonation: ImpersonationReducer,
});

const persistConfig = getPersistConfig({
  key: 'vision',
  storage,
  rootReducer,
  blacklist: ['menu'],
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.REACT_APP_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);
