import { useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams, Outlet, NavLink } from 'react-router-dom';
import { Nav, NavItem, Container, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useOktaAuth } from '@okta/okta-react';
import designStudioService from '../../../services/api/design-studio';
import { setAuthToken } from '../../../services/api';
import SelectArrowLeft from '../../../assets/images/icons/SelectArrowLeft.svg';
import '../styles.css';
import type { Project } from '../../../services/api/design-studio/types';

const ProjectView = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState } = useOktaAuth();
  const [backButtonConfig, setBackButtonConfig] = useState<{ text: string; route: string }>({
    text: 'Projects',
    route: '/design-studio/projects',
  });

  const setRouteBackButton = (routeName?: string) => {
    if (!routeName || !id) {
      setBackButtonConfig({
        text: 'Projects',
        route: '/design-studio/projects',
      });
    } else {
      setBackButtonConfig({
        text: `Project ${routeName}`,
        route: `/design-studio/projects/${id}/${routeName}`,
      });
    }
  };

  const fetchProject = useCallback(async () => {
    try {
      if (id && id !== 'new') {
        const { data } = await designStudioService.getProject(id);
        setProject(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch project:', error);
      navigate('/design-studio/projects');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!authState?.idToken?.idToken) return;
    setAuthToken(authState.idToken.idToken);
    fetchProject();
  }, [id, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const nav = [
    { href: 'details', title: 'Details' },
    { href: 'items', title: 'Items' },
    { href: 'rfqs', title: 'RFQs' },
    { href: 'contacts', title: 'Contacts' },
    { href: 'addresses', title: 'Addresses' },
    { href: 'budget', title: 'Budget' },
    { href: 'audit-log', title: 'Audit Log' },
  ];

  return (
    <div className="w-100 bg-white" style={{ height: 'calc(100vh - 118px)' }}>
      <Container fluid className="h-100">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="2">
              <button
                type="button"
                className="grayButton backToProjectsButton"
                onClick={() => navigate(backButtonConfig.route)}
              >
                <img src={SelectArrowLeft} alt="Back to projects" />
                <div className="d-none d-md-flex text-nowrap">{backButtonConfig.text}</div>
              </button>
            </Col>
            <Col md="9" className="text-center">
              <h5 className="mb-4">{project?.projectName || 'New Project'}</h5>
            </Col>
            <Col md="1"></Col>
          </Row>
        </Container>

        {project?.id && (
          <SimpleBar style={{ borderBottom: '1px solid #DFD6C4' }}>
            <Nav className="justify-content-center p-2">
              {nav.map((item) => (
                <NavItem key={item.href} className="mx-2">
                  <NavLink
                    to={`/design-studio/projects/${id}/${item.href}`}
                    className="px-3 py-1 rounded-pill"
                    style={({ isActive }) => ({
                      backgroundColor:
                        isActive && backButtonConfig.text?.split(' ')[1] !== item.href
                          ? 'var(--bs-primary)'
                          : 'white',
                      color:
                        isActive && backButtonConfig.text?.split(' ')[1] !== item.href
                          ? 'white'
                          : 'var(--bs-primary)',
                      border:
                        backButtonConfig.text?.split(' ')[1] === item.href
                          ? '1px solid var(--bs-primary)'
                          : 'none',
                      cursor: 'pointer',
                      fontSize: '14px',
                      textDecoration: 'none',
                    })}
                  >
                    {item.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </SimpleBar>
        )}

        <div className="py-4 fontSettings">
          <Outlet context={{ project, setRouteBackButton }} />
        </div>
      </Container>
    </div>
  );
};

export default ProjectView;
