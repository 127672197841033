import PropTypes from 'prop-types';
import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

const TooltipButton = ({
  onClick,
  className,
  styles,
  id,
  children,
  tooltipContent,
  color = 'primary',
}) => {
  return (
    <>
      <UncontrolledTooltip target={id}>{tooltipContent}</UncontrolledTooltip>
      <Button
        color={color}
        onClick={onClick}
        id={id}
        className={`d-flex align-items-center ${className}`}
        style={styles}
      >
        {children}
      </Button>
    </>
  );
};

TooltipButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  styles: PropTypes.node,
  id: PropTypes.string,
  children: PropTypes.node,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
};

export default TooltipButton;
