import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import designStudioService from '../../../services/api/design-studio';
import { TableHeaderOverride, TableIconsOverride } from '../projects/components';
import '../tableStyles.css';
import { Project } from '../../../services/api/design-studio/types';

interface Address {
  id: string;
  projectId: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
  city: string;
  stateProvince: string;
  countryId: string;
  isPrimary: boolean;
  typeId: string;
  createdAt: string;
  updatedAt: string;
}

const columns: MRT_ColumnDef<Address>[] = [
  {
    accessorKey: 'streetName',
    header: 'Street Name',
    size: 200,
  },
  {
    accessorKey: 'city',
    header: 'City',
    size: 150,
  },
  {
    accessorKey: 'postalCode',
    header: 'Postal Code',
    size: 120,
  },
  {
    accessorKey: 'stateProvince',
    header: 'State/Province',
    size: 150,
  },
  {
    accessorKey: 'isPrimary',
    header: 'Primary',
    Cell: ({ row }) => (
      <span
        className={`px-2 py-1 rounded-full text-xs font-medium ${
          row.original.isPrimary ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
        }`}
      >
        {row.original.isPrimary ? 'Yes' : 'No'}
      </span>
    ),
    size: 100,
  },
];

const Addresses: FC = () => {
  const navigate = useNavigate();
  const { project } = useOutletContext<{ project: Project }>();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAddresses = async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getProjectAddresses(project.id);
      setAddresses(response.data.data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (address: Address) => {
    navigate(`/design-studio/projects/${project.id}/addresses/${address.id}/details`);
  };

  const handleAddNew = () => {
    navigate(`/design-studio/projects/${project.id}/addresses/new/details`);
  };

  useEffect(() => {
    fetchAddresses();
  }, [project.id]);

  const tableData = useMemo(() => addresses || [], [addresses]);

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading addresses</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchAddresses}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="capexTable">
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        initialState={{
          density: 'compact',
          showColumnFilters: true,
          sorting: [{ id: 'streetAddress', desc: false }],
        }}
        state={{
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        muiTableContainerProps={{
          sx: {
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            maskImage: 'linear-gradient(to left, transparent, black 40px)',
            WebkitMaskImage: 'linear-gradient(to left, transparent, black 40px)',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            borderBottom: '1px solid #DFD6C4',
          },
        }}
        muiTablePaperProps={{
          sx: {
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '40px',
              pointerEvents: 'none',
              zIndex: 1000,
            },
          },
        }}
        renderTopToolbar={({ table }) => (
          <TableHeaderOverride<Address>
            table={table}
            onAddNew={handleAddNew}
            tableName="Addresses"
          />
        )}
        muiTableBodyCellProps={({ row }) => ({
          onDoubleClick: () => handleRowClick(row.original),
          sx: {
            cursor: 'pointer',
            borderBottom: 'none',
          },
        })}
        icons={TableIconsOverride}
      />
    </div>
  );
};

export default Addresses;
