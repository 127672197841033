import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  impersonatedUser: null,
  isImpersonating: false,
};

export const ImpersonationSlice = createSlice({
  name: 'impersonation',
  initialState,
  reducers: {
    startImpersonation: (state, action) => {
      state.impersonatedUser = action.payload;
      state.isImpersonating = true;
    },
    stopImpersonation: (state) => {
      state.impersonatedUser = null;
      state.isImpersonating = false;
    },
    clearImpersonation: () => {
      return initialState;
    },
  },
});

export const { startImpersonation, stopImpersonation, clearImpersonation } =
  ImpersonationSlice.actions;

export default ImpersonationSlice.reducer;
