import { Address, Project } from '../services/api/design-studio/types';

export const defaultAddress: Partial<Address> = {
  streetName: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  isPrimary: false,
  addressType: '',
  countryCode: '',
};

export const defaultProject: Partial<Project> = {
  id: '',
  projectName: '',
  description: '',
  propertyCode: '',
  propertyName: '',
  projectLeadId: '',
  designStatusId: '',
  projectValue: 0,
  workdayProjectReference: '',
  propertyId: '',
  product: '',
  name: '',
  projectCode: '',
  reference: '',
  completionDate: '',
  startDate: new Date().toISOString().split('T')[0],
  region: {
    id: '550e8400-e29b-41d4-a716-446655440006',
    code: '',
    name: '',
    description: '',
    isActive: false,
  },
};
