import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useEffect, useState, type FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import designStudioService from '../../../services/api/design-studio';
import { Contact, Project } from '../../../services/api/design-studio/types';
import { TableHeaderOverride, TableIconsOverride } from '../projects/components';

const columns: MRT_ColumnDef<Contact>[] = [
  {
    accessorKey: 'contact.firstName',
    header: 'First Name',
    size: 150,
  },
  {
    accessorKey: 'contact.lastName',
    header: 'Last Name',
    size: 150,
  },
  {
    accessorKey: 'contact.email',
    header: 'Email',
    size: 200,
  },
  {
    accessorKey: 'contact.phone',
    header: 'Phone',
    size: 150,
  },
  {
    accessorKey: 'contact.role',
    header: 'Role',
    size: 150,
  },
  {
    accessorKey: 'contact.department',
    header: 'Department',
    size: 200,
  },
];

const Contacts: FC = () => {
  const { project } = useOutletContext<{ project: Project }>();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchContacts = async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getProjectContacts(project.id);
      setContacts(response.data.data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNew = () => {};

  useEffect(() => {
    fetchContacts();
  }, [project.id]);

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading contacts</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchContacts}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="capexTable">
      <MaterialReactTable
        columns={columns}
        data={contacts}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        initialState={{
          density: 'compact',
          showColumnFilters: true,
        }}
        state={{
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        muiTableContainerProps={{
          sx: {
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            maskImage: 'linear-gradient(to left, transparent, black 40px)',
            WebkitMaskImage: 'linear-gradient(to left, transparent, black 40px)',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            borderBottom: '1px solid #DFD6C4',
          },
        }}
        muiTableBodyCellProps={() => ({
          sx: {
            cursor: 'pointer',
            borderBottom: 'none',
          },
        })}
        muiTablePaperProps={{
          sx: {
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '40px',
              background: 'linear-gradient(to right, transparent, white)',
              pointerEvents: 'none',
              zIndex: 1000,
            },
          },
        }}
        renderTopToolbar={({ table }) => (
          <TableHeaderOverride<Contact>
            table={table}
            onAddNew={handleAddNew}
            tableName="Contacts"
          />
        )}
        icons={TableIconsOverride}
      />
    </div>
  );
};

export default Contacts;
