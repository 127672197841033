import { Modal, ModalBody } from 'reactstrap';
import '../styles.css';

interface ConfirmModalProps {
  open: boolean;
  message: string;
  title: string;
  loading: boolean;
  onClose: () => void;
  submitButton: React.ReactNode;
}

const ConfirmModal = ({
  open,
  message,
  title,
  loading,
  onClose,
  submitButton,
}: ConfirmModalProps) => {
  return (
    <Modal
      isOpen={open}
      fade={false}
      toggle={() => !loading && onClose()}
      wrapClassName="designStudioConfirmModal"
      centered
    >
      <ModalBody className="d-flex flex-column text-center">
        <div style={{ fontSize: 20, fontWeight: 550, marginBottom: 20 }}>{title}</div>
        {message}
        <div
          className="d-flex w-100
        justify-content-center mt-4"
        >
          <button type="button" disabled={loading} className="grayButton" onClick={onClose}>
            Cancel
          </button>
          <div className="mx-2"></div>
          {submitButton}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ConfirmModal;
