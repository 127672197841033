import {
  MRT_GlobalFilterTextField,
  MRT_RowData,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import { circlePlusIcon, downloadIcon } from '../../../../assets/images/icons/table';
import generateCsvContent from '../../../../helpers/generateCSVData';
import TooltipButton from '../../components/TooltipButton';

interface TableHeaderOverrideProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
  onAddNew?: () => void;
  tableName: string;
}

const TableHeaderOverride = <TData extends MRT_RowData>({
  table,
  onAddNew,
  tableName,
}: TableHeaderOverrideProps<TData>) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="m-1 d-flex">
        <TooltipButton
          id="downloadItems"
          onClick={() => {
            generateCsvContent(
              table.getPrePaginationRowModel().rows,
              table.getFlatHeaders(),
              tableName,
              true,
            );
          }}
          tooltipContent={`Download ${tableName} CSV`}
          className="filtersGroupDownloadProjectsButton"
        >
          <img src={downloadIcon} alt={`download ${tableName}`} />
        </TooltipButton>
      </div>
      <div className="d-flex align-items-center" style={{ zIndex: 5 }}>
        <MRT_GlobalFilterTextField table={table} className="globalFilterField" />
        <div className="tableFiltersGroup">
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} className="filterButton" />
          <MRT_ToggleDensePaddingButton table={table} color="secondary" />
          <MRT_ToggleFullScreenButton table={table} className="fullScreenButton" />
          {onAddNew && (
            <TooltipButton
              id="addNewProject"
              tooltipContent={`Add new ${tableName}`}
              onClick={onAddNew}
              className="filtersGroupAddButton"
            >
              <img src={circlePlusIcon} alt="add project" />
              <div style={{ paddingRight: 14, paddingLeft: 6 }}>Add</div>
            </TooltipButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeaderOverride;
