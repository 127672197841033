import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState, type FC } from 'react';
import { Controller, ControllerFieldState, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import WorkdayLogo from '../../../../assets/images/icons/WorkDayLogoRounded.svg';
import DropdownIndicator from '../../../../components/SelectDropdownArrow';
import { setAuthToken } from '../../../../services/api';
import designStudioService from '../../../../services/api/design-studio';
import type {
  Lead,
  Message,
  Project,
  Property,
  Status,
} from '../../../../services/api/design-studio/types';
import SkeletonedComponent from '../../../guest-dashboard2/SkeletonedComponent';
import FooterActionbar from '../../components/FooterActionbar';
import '../../styles.css';
import { defaultProject } from '../../../../constants';

interface ApiResponse<T> {
  status: string;
  data: T[];
  meta: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
  };
}

const inputStyle = (fieldState: ControllerFieldState) => ({
  border: fieldState.isDirty ? '1px solid #997b40' : '1px solid #dfd6c4',
});

const Details: FC = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { project } = useOutletContext<{
    project: Project;
  }>();
  const [designStatuses, setDesignStatuses] = useState<Status[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message>({ type: '', text: '' });

  const newProject = !project?.id;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
    setValue,
    watch,
  } = useForm<Project>({
    defaultValues: newProject ? defaultProject : project,
  });

  useEffect(() => {
    if (!authState?.idToken?.idToken) return;
    setAuthToken(authState.idToken.idToken);

    const fetchData = async () => {
      setLoading(true);
      try {
        const [designStatusResponse, propertiesResponse, leadsResponse] = await Promise.all([
          designStudioService.getDesignStatuses(),
          designStudioService.getProperties(),
          designStudioService.getLeads(),
        ]);

        setDesignStatuses((designStatusResponse?.data as unknown as ApiResponse<Status>).data);
        setProperties((propertiesResponse?.data as unknown as ApiResponse<Property>).data);
        setLeads((leadsResponse?.data as unknown as ApiResponse<Lead>).data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authState?.idToken?.idToken]);

  const onSubmit = async (values: Project) => {
    try {
      const formattedData = {
        name: values.projectName,
        description: values.description,
        propertyCode: values.propertyCode,
        propertyName: values.propertyName,
        projectLeadId: values.projectLeadId,
        designStatusId: values.designStatusId,
        projectNameId: values.projectName,
        ...(newProject && {
          startDate: new Date().toISOString().split('T')[0],
          region: '550e8400-e29b-41d4-a716-446655440010',
          projectValue: 0,
          localCurrencyId: '550e8400-e29b-41d4-a716-446655440006',
        }),
      };

      if (newProject) {
        await designStudioService.createProject(formattedData);
        navigate('/design-studio/projects');
        setMessage({ type: 'success', text: 'Project created successfully!' });
      } else {
        await designStudioService.updateProject(project!.id, formattedData);
        reset(values);
        setMessage({ type: 'success', text: 'Saved!' });
      }
    } catch (err) {
      setMessage({ type: 'fail', text: 'Error occured!' });
    }
  };

  const handleCancelButtonClick = () => {
    if (newProject) {
      reset(defaultProject);
    } else {
      reset(project);
    }
  };

  useEffect(() => {
    if (message.type) {
      setTimeout(() => setMessage({ text: '', type: '' }), 3000);
    }
  }, [message]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="containerMaxWidth" fluid>
        <Row className="g-5 w-100">
          <Col md="6" className="d-flex flex-column h-100">
            <div>
              <h5 className="mb-4 bold">Project Details</h5>

              <FormGroup>
                <Label>Design Status</Label>
                <SkeletonedComponent condition={!loading} height={35}>
                  <Controller
                    name="designStatusId"
                    control={control}
                    rules={{ required: 'Design status is required' }}
                    render={({ field, fieldState }) => (
                      <Input type="select" {...field} style={inputStyle(fieldState)}>
                        <option value="">Select status...</option>
                        {designStatuses.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </Input>
                    )}
                  />
                </SkeletonedComponent>
              </FormGroup>

              <FormGroup>
                <Label>Project Lead *</Label>
                <SkeletonedComponent condition={!loading} height={35}>
                  <Controller
                    name="projectLeadId"
                    control={control}
                    rules={{ required: 'Project lead is required' }}
                    render={({ field, fieldState }) => (
                      <Input type="select" {...field} style={inputStyle(fieldState)}>
                        <option value="">Select project lead...</option>
                        {leads
                          .filter((lead) => lead.isActive)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((lead) => (
                            <option key={lead.id} value={lead.id}>
                              {lead.name}
                            </option>
                          ))}
                      </Input>
                    )}
                  />
                </SkeletonedComponent>
              </FormGroup>

              <FormGroup>
                <Label>Project Name *</Label>
                <SkeletonedComponent condition={!loading} height={35}>
                  <Controller
                    name="projectName"
                    control={control}
                    rules={{ required: 'Project name is required' }}
                    render={({ field, fieldState }) => (
                      <Input style={inputStyle(fieldState)} type="text" {...field} />
                    )}
                  />
                </SkeletonedComponent>
              </FormGroup>

              <FormGroup>
                <Label>Property *</Label>
                <SkeletonedComponent condition={!loading} height={35}>
                  <Controller
                    name="propertyCode"
                    control={control}
                    defaultValue={project?.propertyCode}
                    render={({ field, fieldState }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        options={properties.map((p) => ({
                          value: p.referenceId,
                          label: p.name,
                        }))}
                        value={{ value: watch('propertyCode'), label: watch('propertyName') }}
                        onChange={(code) => {
                          setValue('propertyName', code!.label);
                          field.onChange(code!.value);
                        }}
                        isSearchable
                        placeholder="Select property"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: fieldState.isDirty ? '1px solid #997b40' : '1px solid #dfd6c4',
                            boxShadow: 'none',
                          }),
                          option: (baseStyles, { isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected ? '#F6F4F0' : 'white',
                            color: 'black',
                            cursor: 'pointer',
                          }),
                        }}
                        className="PropertySelect"
                      />
                    )}
                  />
                </SkeletonedComponent>
              </FormGroup>

              <FormGroup>
                <Label>Project Description *</Label>
                <SkeletonedComponent condition={!loading} height={110}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input style={inputStyle(fieldState)} type="textarea" {...field} rows={4} />
                    )}
                  />
                </SkeletonedComponent>
              </FormGroup>
            </div>
          </Col>
          <Col md={6}>
            <div className="fontSettings">
              <h5 className="mb-4 bold">Workday Project Details</h5>
              <Row className="p-3 mb-3" style={{ backgroundColor: '#f6f4f0' }}>
                <Col md="6" className="d-flex flex-column h-100">
                  <Label className="bold">Project Value</Label>
                  <div className="bold">
                    {!newProject && project.projectValue
                      ? new Intl.NumberFormat('en-GB', {
                          style: 'currency',
                          currency: project.currency?.code || 'GBP',
                        }).format(project.projectValue)
                      : 'N/A'}
                  </div>
                </Col>
              </Row>
              <Row className="p-3 mb-3" style={{ backgroundColor: '#f6f4f0' }}>
                <FormGroup>
                  <Label>Link to Workday project</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="workdayProjectReference"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                  <div className="text-end mt-2">
                    <a
                      href="#"
                      className="text-primary text-decoration-none d-flex align-items-center justify-content-end"
                    >
                      <span style={{ borderBottom: '1px solid #997B40' }}>
                        View project in Workday{' '}
                      </span>
                      <img src={WorkdayLogo} alt="workday-logo" style={{ marginLeft: 10 }} />
                    </a>
                  </div>
                </FormGroup>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {Object.keys(dirtyFields).length || message.type ? (
        <FooterActionbar onCancel={handleCancelButtonClick} message={message} />
      ) : (
        ''
      )}
    </form>
  );
};

export default Details;
